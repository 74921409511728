exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accountant-course-training-js": () => import("./../../../src/pages/accountant-course-training.js" /* webpackChunkName: "component---src-pages-accountant-course-training-js" */),
  "component---src-pages-bas-lodgment-adelaide-js": () => import("./../../../src/pages/bas-lodgment-adelaide.js" /* webpackChunkName: "component---src-pages-bas-lodgment-adelaide-js" */),
  "component---src-pages-blog-a-complete-guide-to-the-australian-retirement-trust-js": () => import("./../../../src/pages/blog/a-complete-guide-to-the-australian-retirement-trust.js" /* webpackChunkName: "component---src-pages-blog-a-complete-guide-to-the-australian-retirement-trust-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-personal-services-income-guide-js": () => import("./../../../src/pages/blog/personal-services-income-guide.js" /* webpackChunkName: "component---src-pages-blog-personal-services-income-guide-js" */),
  "component---src-pages-blog-what-are-the-non-concessional-contributions-a-complete-guide-js": () => import("./../../../src/pages/blog/what-are-the-non-concessional-contributions-a-complete-guide.js" /* webpackChunkName: "component---src-pages-blog-what-are-the-non-concessional-contributions-a-complete-guide-js" */),
  "component---src-pages-bookkeeping-adelaide-js": () => import("./../../../src/pages/bookkeeping-adelaide.js" /* webpackChunkName: "component---src-pages-bookkeeping-adelaide-js" */),
  "component---src-pages-business-advisor-js": () => import("./../../../src/pages/business-advisor.js" /* webpackChunkName: "component---src-pages-business-advisor-js" */),
  "component---src-pages-business-insurance-in-adelaide-js": () => import("./../../../src/pages/business-insurance-in-adelaide.js" /* webpackChunkName: "component---src-pages-business-insurance-in-adelaide-js" */),
  "component---src-pages-business-launching-packages-js": () => import("./../../../src/pages/business-launching-packages.js" /* webpackChunkName: "component---src-pages-business-launching-packages-js" */),
  "component---src-pages-business-registration-js": () => import("./../../../src/pages/business-registration.js" /* webpackChunkName: "component---src-pages-business-registration-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-client-portal-js": () => import("./../../../src/pages/client-portal.js" /* webpackChunkName: "component---src-pages-client-portal-js" */),
  "component---src-pages-company-registration-adelaide-js": () => import("./../../../src/pages/company-registration-adelaide.js" /* webpackChunkName: "component---src-pages-company-registration-adelaide-js" */),
  "component---src-pages-company-tax-return-js": () => import("./../../../src/pages/company-tax-return.js" /* webpackChunkName: "component---src-pages-company-tax-return-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-deceased-estate-tax-return-js": () => import("./../../../src/pages/deceased-estate-tax-return.js" /* webpackChunkName: "component---src-pages-deceased-estate-tax-return-js" */),
  "component---src-pages-family-trust-tax-return-js": () => import("./../../../src/pages/family-trust-tax-return.js" /* webpackChunkName: "component---src-pages-family-trust-tax-return-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-financial-advisors-adelaide-js": () => import("./../../../src/pages/financial-advisors-adelaide.js" /* webpackChunkName: "component---src-pages-financial-advisors-adelaide-js" */),
  "component---src-pages-franchise-opportunities-js": () => import("./../../../src/pages/franchise-opportunities.js" /* webpackChunkName: "component---src-pages-franchise-opportunities-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-individual-abn-registration-adelaide-js": () => import("./../../../src/pages/individual-abn-registration-adelaide.js" /* webpackChunkName: "component---src-pages-individual-abn-registration-adelaide-js" */),
  "component---src-pages-jobkeeper-payment-js": () => import("./../../../src/pages/jobkeeper-payment.js" /* webpackChunkName: "component---src-pages-jobkeeper-payment-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-medical-healthcare-professionals-js": () => import("./../../../src/pages/medical-healthcare-professionals.js" /* webpackChunkName: "component---src-pages-medical-healthcare-professionals-js" */),
  "component---src-pages-mortgage-broker-adelaide-js": () => import("./../../../src/pages/mortgage-broker-adelaide.js" /* webpackChunkName: "component---src-pages-mortgage-broker-adelaide-js" */),
  "component---src-pages-partnership-js": () => import("./../../../src/pages/partnership.js" /* webpackChunkName: "component---src-pages-partnership-js" */),
  "component---src-pages-partnership-registration-adelaide-js": () => import("./../../../src/pages/partnership-registration-adelaide.js" /* webpackChunkName: "component---src-pages-partnership-registration-adelaide-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-second-look-assessment-js": () => import("./../../../src/pages/second-look-assessment.js" /* webpackChunkName: "component---src-pages-second-look-assessment-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-small-business-tax-return-js": () => import("./../../../src/pages/small-business-tax-return.js" /* webpackChunkName: "component---src-pages-small-business-tax-return-js" */),
  "component---src-pages-smsf-administration-services-js": () => import("./../../../src/pages/smsf-administration-services.js" /* webpackChunkName: "component---src-pages-smsf-administration-services-js" */),
  "component---src-pages-smsf-registration-adelaide-js": () => import("./../../../src/pages/smsf-registration-adelaide.js" /* webpackChunkName: "component---src-pages-smsf-registration-adelaide-js" */),
  "component---src-pages-sole-trader-tax-js": () => import("./../../../src/pages/sole-trader-tax.js" /* webpackChunkName: "component---src-pages-sole-trader-tax-js" */),
  "component---src-pages-student-tax-checklist-js": () => import("./../../../src/pages/student-tax-checklist.js" /* webpackChunkName: "component---src-pages-student-tax-checklist-js" */),
  "component---src-pages-super-refund-services-adelaide-js": () => import("./../../../src/pages/super-refund-services-adelaide.js" /* webpackChunkName: "component---src-pages-super-refund-services-adelaide-js" */),
  "component---src-pages-tax-return-checklist-js": () => import("./../../../src/pages/tax-return-checklist.js" /* webpackChunkName: "component---src-pages-tax-return-checklist-js" */),
  "component---src-pages-tax-return-deadline-approaching-js": () => import("./../../../src/pages/tax-return-deadline-approaching.js" /* webpackChunkName: "component---src-pages-tax-return-deadline-approaching-js" */),
  "component---src-pages-tax-return-for-agricultural-worker-js": () => import("./../../../src/pages/tax-return-for-agricultural-worker.js" /* webpackChunkName: "component---src-pages-tax-return-for-agricultural-worker-js" */),
  "component---src-pages-tax-return-for-construction-workers-js": () => import("./../../../src/pages/tax-return-for-construction-workers.js" /* webpackChunkName: "component---src-pages-tax-return-for-construction-workers-js" */),
  "component---src-pages-tax-return-for-doctors-js": () => import("./../../../src/pages/tax-return-for-doctors.js" /* webpackChunkName: "component---src-pages-tax-return-for-doctors-js" */),
  "component---src-pages-tax-return-for-freelancers-js": () => import("./../../../src/pages/tax-return-for-freelancers.js" /* webpackChunkName: "component---src-pages-tax-return-for-freelancers-js" */),
  "component---src-pages-tax-return-for-homeowners-js": () => import("./../../../src/pages/tax-return-for-homeowners.js" /* webpackChunkName: "component---src-pages-tax-return-for-homeowners-js" */),
  "component---src-pages-tax-return-for-lawyers-js": () => import("./../../../src/pages/tax-return-for-lawyers.js" /* webpackChunkName: "component---src-pages-tax-return-for-lawyers-js" */),
  "component---src-pages-tax-return-for-meat-workers-js": () => import("./../../../src/pages/tax-return-for-meat-workers.js" /* webpackChunkName: "component---src-pages-tax-return-for-meat-workers-js" */),
  "component---src-pages-tax-return-for-ndis-js": () => import("./../../../src/pages/tax-return-for-ndis.js" /* webpackChunkName: "component---src-pages-tax-return-for-ndis-js" */),
  "component---src-pages-tax-return-for-nurses-js": () => import("./../../../src/pages/tax-return-for-nurses.js" /* webpackChunkName: "component---src-pages-tax-return-for-nurses-js" */),
  "component---src-pages-tax-return-for-retail-industry-workers-js": () => import("./../../../src/pages/tax-return-for-retail-industry-workers.js" /* webpackChunkName: "component---src-pages-tax-return-for-retail-industry-workers-js" */),
  "component---src-pages-tax-return-for-students-js": () => import("./../../../src/pages/tax-return-for-students.js" /* webpackChunkName: "component---src-pages-tax-return-for-students-js" */),
  "component---src-pages-tax-return-in-adelaide-js": () => import("./../../../src/pages/tax-return-in-adelaide.js" /* webpackChunkName: "component---src-pages-tax-return-in-adelaide-js" */),
  "component---src-pages-tax-return-online-js": () => import("./../../../src/pages/tax-return-online.js" /* webpackChunkName: "component---src-pages-tax-return-online-js" */),
  "component---src-pages-tax-returns-for-property-investors-js": () => import("./../../../src/pages/tax-returns-for-property-investors.js" /* webpackChunkName: "component---src-pages-tax-returns-for-property-investors-js" */),
  "component---src-pages-teacher-tax-returns-adelaide-js": () => import("./../../../src/pages/teacher-tax-returns-adelaide.js" /* webpackChunkName: "component---src-pages-teacher-tax-returns-adelaide-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-tools-resources-js": () => import("./../../../src/pages/tools-resources.js" /* webpackChunkName: "component---src-pages-tools-resources-js" */),
  "component---src-pages-trading-name-registrations-adelaide-js": () => import("./../../../src/pages/trading-name-registrations-adelaide.js" /* webpackChunkName: "component---src-pages-trading-name-registrations-adelaide-js" */),
  "component---src-pages-trust-registration-adelaide-js": () => import("./../../../src/pages/trust-registration-adelaide.js" /* webpackChunkName: "component---src-pages-trust-registration-adelaide-js" */),
  "component---src-pages-university-student-tax-return-js": () => import("./../../../src/pages/university-student-tax-return.js" /* webpackChunkName: "component---src-pages-university-student-tax-return-js" */),
  "component---src-pages-wealth-management-service-in-adelaide-js": () => import("./../../../src/pages/wealth-management-service-in-adelaide.js" /* webpackChunkName: "component---src-pages-wealth-management-service-in-adelaide-js" */),
  "component---src-pages-working-holiday-visa-tax-return-js": () => import("./../../../src/pages/working-holiday-visa-tax-return.js" /* webpackChunkName: "component---src-pages-working-holiday-visa-tax-return-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

